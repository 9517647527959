<template>
<div class="err-bac">
  <h3>抱歉，您请求的文件不存在！</h3>
</div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>
.err-bac {
  width: 100%;
  height: 100%;
  background: #444;

}
h3 {
  width: 100%;
  position:absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  font-size:60px;
  color:#eee;
  text-align:center;
  margin-top: 60px;
  font-weight:normal;
}
</style>
